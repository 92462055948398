
import { ValidatorFn, AbstractControl } from '@angular/forms';

export class CustomDateValidator {
    public static startToEndDate(fromDateField: string, toDateField: string, errorName: string = 'fromToDate'): ValidatorFn {
        return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
            const fromDate = formGroup.get(fromDateField).value
                  , toDate = formGroup.get(toDateField).value;
            if ((fromDate != null && toDate != null)) {
                var tDate = new Date(toDate);
                var fDate = new Date(fromDate);  
                if(fDate <= tDate) {
                    return null;
                } else {
                    return {[errorName]: true};
                  }
            } else {
                return null;
            }
        }
    }

    public static startToEndDateTime(fromDateField: string, fromHourField: string, fromMinuteField: string, toDateField: string, toHourField: string, toMinuteField: string, errorName: string = 'fromToDate'): ValidatorFn {
        return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
            const fromDate = formGroup.get(fromDateField).value
                  , fromHour = formGroup.get(fromHourField).value
                  , fromMinute = formGroup.get(fromMinuteField).value
                  , toDate = formGroup.get(toDateField).value
                  , toHour = formGroup.get(toHourField).value
                  , toMinute = formGroup.get(toMinuteField).value;
            if ((fromDate != null && toDate != null)) {
                var tDate = new Date(toDate).setHours(toHour, toMinute);
                var fDate = new Date(fromDate).setHours(fromHour, fromMinute);  
                if(fDate <= tDate) {
                    return null;
                } else {
                    return {[errorName]: true};
                }
            } else {
                return null;
            }
        }
    }
  }